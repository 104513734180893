import React from 'react'
import Header from './Header'
import OdometerAnimation from '../../General/Ordometer'
import logo from '../../../img/usecases/zume/image1.webp'
import Progressbar from '../home/files/Progressbar'
import { Link } from 'react-router-dom'
import videoLink from '../../../videos/zume bg.mp4'
import zumeColors from '../../../videos/colors-zume2.webm'

export default function Zume() {
   const scrollTop = ()=>{
      window.scrollTo(0, 0);
   }
  return (
    <>
         <Header />
   <OdometerAnimation />
    <main id='zume' className='usecases zume'>
   <Progressbar />
      <section className="usecases__head usecases-head">
      <div className="head__video" dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoPlay
          playsInline
          src="${videoLink}"
        ></video>
      ` }}></div>
         <div className="usecases-head__container">
            <div className="usecases-head__content">
               <h1 className="usecases-head__title">
                  <i className="usecases-head__icon">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 39" fill="none">
                        <path d="M18.3524 0.467829L17.4026 17.3959L1.30232 13.0899L0.478474 15.5834L16.0225 21.7355L6.99468 36.0172L9.13154 37.544L19.6656 24.4397L30.1996 37.544L32.3364 36.0172L23.3086 21.7355L38.8526 15.5834L38.0288 13.0899L21.9285 17.3959L20.9787 0.467829L18.3524 0.467829Z" fill="white" stroke="white" stroke-width="2.6305"/>
                     </svg>
                  </i>
                  <div className="usecases-head__text">Zume</div>
               </h1>
            </div>
         </div>
      </section>
      <section className='usecases__text-content usecases-text-content'>
         <div className='usecases-text-content__container'>
            {/* <h2 className="usecases-text-content__title">Title will come here</h2> */}
            <p className="usecases-text-content__text">
            <span>Empowering brands, engaging audiences.</span> Stand out in the digital crowd with Zume.
            </p>
         </div>
      </section>
      <section className='zume__logo-section zume-logo-section'>
         <img className='zume-logo-section__logo' src={logo} alt="logo" />
      </section>
      <section className="zume-font">
         <div className='zume-font__container'>
            <div className="zume-font__row">
               <div className="zume-font__column">Regular</div>
               <div className="zume-font__column zume-font__column_font-name">Bandica</div>
            </div>
            <div className="zume-font__row zume-font__row_img">
            </div>
               <div className="zume-font__column_font-name">Bandica</div>
            </div>
      </section>
      <section className='video-section'>
         <div className="video-section__container">
            <div className="usecases__video zume-colors" dangerouslySetInnerHTML={{ __html: `
           <video
             loop
             muted
             autoPlay
             playsInline
             src="${zumeColors}"
           ></video>
         ` }}>
            </div>
         </div>
      </section>
      <section className="usecases__image usecases-image">
         <div className='usecases-image__container'>
            <div className="usecases-image__pic usecases-image_13">
            </div>
         </div>
      </section>
      <section className='usecases__pair-col usecases-pair usecases-pair__container usecases-pair-1'>
         <div className="usecases-pair__column usecases-pair__column_3 -ibg"></div>
         <div className="usecases-pair__column usecases-pair__column_4 -ibg"></div>
      </section>
      <section className='usecases__text-content usecases-text-content dir-r'>
         <div className='usecases-text-content__container'>
            <p className="usecases-text-content__text">
            Crafting <span>an exceptional and distinctive brand</span> guideline that embodies Zume's unique approach is what truly distinguishes it from the rest.
            </p>
         </div>
      </section>
      <section className="usecases__image usecases-image">
         <div className='usecases-image__container'>
            <div className="usecases-image__pic usecases-image_14">
            </div>
         </div>
      </section>
      <section className="usecases__image usecases-image">
         <div className='usecases-image__container'>
            <div className="usecases-image__pic usecases-image_15">
            </div>
         </div>
      </section>
      <section className="usecases__image usecases-image">
            <div className='usecases-image__container'>
               <div className="usecases-image__pic usecases-image_2">
               </div>
            </div>
      </section>
      <section className='usecases__pair-col usecases-pair usecases-pair-3'>
         <div className='usecases-pair__container'>
            <div className="usecases-pair__column usecases-pair__column_5 -ibg"></div>
            <div className="usecases-pair__column usecases-pair__column_6 -ibg"></div>
         </div>
      </section>
      <section className='usecases__text-content usecases-text-content'>
         <div className='usecases-text-content__container'>
            <p className="usecases-text-content__text">
            Specialized in generating leads and directing attention to brands that excel in <span>navigating complex markets through innovation.</span>
            </p>
         </div>
      </section>
      <section className="usecases__image usecases-image">
            <div className='usecases-image__container'>
               <div className="usecases-image__pic usecases-image_3">
               </div>
            </div>
      </section>
      <section className='usecases__pair-col usecases-pair usecases-pair-3'>
         <div className='usecases-pair__container'>
            <div className="usecases-pair__column usecases-pair__column_7 -ibg"></div>
            <div className="usecases-pair__column usecases-pair__column_8 -ibg"></div>
         </div>
      </section>
      <section className="usecases__image usecases-image">
            <div className='usecases-image__container'>
               <div className="usecases-image__pic usecases-image_4">
               </div>
            </div>
      </section>
      <section className='usecases__pair-col usecases-pair usecases-pair-3'>
         <div className='usecases-pair__container'>
            <div className="usecases-pair__column usecases-pair__column_9 -ibg"></div>
            <div className="usecases-pair__column usecases-pair__column_10 -ibg"></div>
         </div>
      </section>
      <section className="usecases__image usecases-image">
         <div className='usecases-image__container'>
            <div className="usecases-image__pic usecases-image_6">
            </div>
         </div>
      </section>
      <nav className='usecases__navigation usecases-navigation'>
         <div className='usecases-navigation__container'>
            <Link to={'/venom'} onClick={scrollTop} className="usecases-navigation__arrow usecases-navigation__prev">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266 15" fill="none">
               <path d="M0.292908 6.88667C-0.0976258 7.27719 -0.0976257 7.91036 0.292908 8.30088L6.65686 14.6648C7.04739 15.0554 7.68054 15.0554 8.07108 14.6648C8.46158 14.2743 8.46158 13.6412 8.07108 13.2506L2.41422 7.59377L8.07107 1.93692C8.46158 1.54639 8.46158 0.913229 8.07107 0.522705C7.68054 0.13218 7.04739 0.13218 6.65686 0.522705L0.292908 6.88667ZM266 6.59375L1 6.59377L1 8.59377L266 8.59375L266 6.59375Z" fill="white"/>
            </svg>
            </Link>
            <Link to={'/hetrix'} onClick={scrollTop} className="usecases-navigation__arrow usecases-navigation__next">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266 15" fill="none">
                  <path d="M265.707 8.3009C266.098 7.91038 266.098 7.27721 265.707 6.88669L259.343 0.522728C258.953 0.132203 258.319 0.132203 257.929 0.522727C257.538 0.913252 257.538 1.54642 257.929 1.93694L263.586 7.5938L257.929 13.2506C257.538 13.6412 257.538 14.2743 257.929 14.6649C258.319 15.0554 258.953 15.0554 259.343 14.6649L265.707 8.3009ZM-1.74846e-07 8.59375L265 8.5938L265 6.5938L1.74846e-07 6.59375L-1.74846e-07 8.59375Z" fill="white"/>
               </svg>
            </Link>
         </div>
      </nav>
      </main>
    </>
  )
}
