import React from 'react'

export default function Media() {
  return (
   <div className="media">
   <ul className="media__list">
      <li className='media__item'>
         <a href="https://www.behance.net/Proyect_io" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 46 46" fill="none">
               <path d="M17.9933 22.0983C19.7915 20.6949 20.9507 18.5096 20.9507 16.0574C20.9507 11.8325 17.5136 8.39539 13.2889 8.39539H1.52589C1.1212 8.39539 0.733084 8.55615 0.446924 8.84231C0.160763 9.12847 0 9.51659 0 9.92128L0 36.0798C0 36.4845 0.160763 36.8726 0.446924 37.1587C0.733084 37.4449 1.1212 37.6057 1.52589 37.6057H13.2889C17.959 37.6057 21.7582 33.8066 21.7582 29.1372C21.7582 26.2064 20.2618 23.6193 17.9933 22.0983ZM13.2889 11.4472C15.8308 11.4472 17.8989 13.5155 17.8989 16.0574C17.8989 18.5993 15.8308 20.6674 13.2889 20.6674H3.05179V11.4472H13.2889ZM13.2889 34.5539H3.05179V23.7192H13.2889C16.2762 23.7192 18.7064 26.1497 18.7064 29.137C18.7064 32.1238 16.2762 34.5539 13.2889 34.5539ZM34.826 15.258C28.6648 15.258 23.6522 20.2705 23.6522 26.4319C23.6522 32.5932 28.6648 37.6057 34.826 37.6057C37.8106 37.6057 40.6166 36.4433 42.7273 34.3327C43.0084 34.0455 43.1649 33.6591 43.1627 33.2572C43.1606 32.8553 43 32.4705 42.7158 32.1863C42.4317 31.9022 42.0469 31.7416 41.645 31.7394C41.2431 31.7373 40.8566 31.8938 40.5694 32.1749C39.035 33.7089 36.9954 34.5539 34.826 34.5539C30.8692 34.5539 27.566 31.709 26.8498 27.9577H44.4741C44.8788 27.9577 45.2669 27.797 45.5531 27.5108C45.8392 27.2247 46 26.8365 46 26.4319C46 20.2705 40.9873 15.258 34.826 15.258ZM34.826 18.3098C38.7827 18.3098 42.086 21.1545 42.8023 24.906H26.8498C27.566 21.1545 30.8692 18.3098 34.826 18.3098ZM27.9603 12.2545H40.8781C41.0785 12.2545 41.2769 12.215 41.462 12.1383C41.6471 12.0616 41.8154 11.9492 41.9571 11.8075C42.0987 11.6659 42.2111 11.4976 42.2878 11.3125C42.3645 11.1274 42.404 10.929 42.404 10.7286C42.404 10.3239 42.2432 9.93577 41.9571 9.64961C41.6709 9.36345 41.2828 9.20268 40.8781 9.20268H27.9603C27.5556 9.20268 27.1675 9.36345 26.8813 9.64961C26.5951 9.93577 26.4344 10.3239 26.4344 10.7286C26.4344 10.929 26.4738 11.1274 26.5505 11.3125C26.6272 11.4976 26.7396 11.6659 26.8813 11.8075C27.023 11.9492 27.1912 12.0616 27.3763 12.1383C27.5615 12.215 27.7599 12.2545 27.9603 12.2545Z"/>
            </svg>
         </a>
      </li>
      <li className='media__item' style={{scale: '0.7'}}>
         <a href="https://twitter.com/proyect_io" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 37 38" fill="none">
               <g clipPath="url(#clip0_22_2310)">
                 <path d="M22.0236 16.1688L35.8 0.5H32.5366L20.5695 14.1022L11.0186 0.5H0L14.446 21.0709L0 37.5H3.2634L15.8927 23.1325L25.9814 37.5H37M4.44123 2.90793H9.45473L32.5341 35.2104H27.5194"/>
               </g>
               <defs>
                 <clipPath id="clip0_22_2310">
                   <rect width="37" height="37" fill="white" transform="translate(0 0.5)"/>
                 </clipPath>
               </defs>
            </svg>
         </a>
      </li>
      
   </ul>
</div>
  )
}
